// GoogleAnalytics.js
// eslint-disable-next-line
import React, { useEffect } from 'react';

function GoogleAnalytics() {
    useEffect(() => {
        const script1 = document.createElement('script');
        script1.src = 'https://www.googletagmanager.com/gtag/js?id=G-NX8N8YZJNN';
        script1.async = true;
        document.body.appendChild(script1);

        const script2 = document.createElement('script');
        script2.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-NX8N8YZJNN');
        `;
        document.body.appendChild(script2);

        return () => {
            document.body.removeChild(script1);
            document.body.removeChild(script2);
        };
    }, []);

    return null; // This component does not render anything visible
}

export default GoogleAnalytics;
