import React from 'react'
import { Box, Container, Typography, Stack, Link } from '@mui/material'
import GoogleAnalytics from '../google/GoogleAnalytics'

function Footer() {
    return (
        <>
            { <GoogleAnalytics/> }
            <Box py={{ xs: 3, sm: 4 }} component='footer' sx={{ backgroundColor: 'primary.main', color: 'common.white' }}>
    <Container fixed>
        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={{ xs: 'center', sm: 'space-between' }} alignItems={'center'} spacing={2} useFlexGap>
            <Typography fontSize={14}>&#169;2024 Big Box Storage</Typography>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} alignItems={'center'}>
                <Link href="https://www.bigbox.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer" color={'common.white'} fontSize={14} underline="none" sx={{ cursor: 'pointer' }}>
                    Terms & Conditions
                </Link>
                <Link href="https://www.bigbox.com/privacy-policy/" target="_blank" rel="noopener noreferrer" color={'common.white'} fontSize={14} underline="none" sx={{ cursor: 'pointer' }}>
                    Privacy Policy
                </Link>
                <Link href="https://www.bigbox.com/cookie-policy/" target="_blank" rel="noopener noreferrer" color={'common.white'} fontSize={14} underline="none" sx={{ cursor: 'pointer' }}>
                    Cookies Policy
                </Link>
            </Stack>
        </Stack>
    </Container>
</Box>

        </>
    )
}

export default Footer
